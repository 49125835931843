import { Controller } from "@hotwired/stimulus";
import posthog from "posthog-js";

// Connects to data-controller="cookies"
export default class extends Controller {
  static targets = ["analyticsCheckbox"];

  submitEnd() {
    var cookie_consented = this.analyticsCheckboxTarget.checked;

    if (cookie_consented != true) {
      posthog.opt_out_capturing();
      console.log("Opted out of PostHog!");
    }
  }
}
