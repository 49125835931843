import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="activities-map"
export default class extends Controller {
  static targets = ["mapContainer"];
  static values = { polyline: Array };

  connect() {
    // map tile layer
    var osm = L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    // initialise map
    var map = L.map(this.mapContainerTarget, {
      center: [12.898, 77.581],
      touchZoom: false,
      scrollWheelZoom: false,
      dragging: false,
      zoomControl: false,
      zoom: 12,
      layers: [osm],
    });

    // create activity polyline and fit map bounds
    var polyline = L.polyline(this.polylineValue, {
      color: "green",
    }).addTo(map);
    map.fitBounds(polyline.getBounds());
  }
}
