import { Controller } from "@hotwired/stimulus";
import posthog from "posthog-js";

// Connects to data-controller="posthog"
export default class extends Controller {
  reset_session(event) {
    if (event.detail.success) {
      posthog.reset();
    }
  }
}
