// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";
import "./src/jquery"; // if jQuery is used in stimulus controllers add before line 3
import {} from "jquery-ujs";
import "jquery-ui";
import "./parsley.js";
import "./leaflet_1_5_1.js";
import "./leaflet_draw_1_0_4.js";
import posthog from "posthog-js";

if (!posthog.has_opted_out_capturing()) {
  posthog.init("phc_b9V0srgbOEOxqPlG9ficuHvIA8WfpchbuOOg1ngWLNP", {
    api_host: "https://pha.altmo.app",
    person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
  });
}
