import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

const domainName = "https://cycleto.work";

// Connects to data-controller="components"
export default class extends Controller {
  static targets = [
    "plus_button",
    "dropdown",
    "carouselContent",
    "topEntitiesDiv",
    "summaryCardDetails",
    "f2wcrDropdown",
    "rankingDetails",
    "rankingDropdownText",
    "globalGenderToggle",
    "cityGenderToggle",
    "cityIdDiv",
    "dropdownButton",
  ];

  toggle_card(event) {
    var button_id, button, collapse_content;

    button_id = event.target
      .closest("#parent")
      .getAttribute("data-parent-index");
    button = document.getElementById(`button-${button_id}`);
    collapse_content = document.getElementById(`collapse-content-${button_id}`);

    if (collapse_content.classList.contains("hidden")) {
      collapse_content.classList.remove("hidden");
      button.src = domainName + "/minus_icon.svg";
    } else {
      collapse_content.classList.add("hidden");
      button.src = domainName + "/plus_icon.svg";
    }
  }

  toggle_select_dropdown() {
    this.dropdownTarget.classList.toggle("hidden");
  }

  hide_select_dropdown(event) {
    if (
      event &&
      (this.dropdownTarget.contains(event.target) ||
        this.dropdownButtonTarget.contains(event.target))
    ) {
      // event.preventDefault(); // I don't remeber why I did it, but i need this line to be commented
      return;
    }

    this.dropdownTarget.classList.add("hidden");
  }

  toggle_summary_card_details(event) {
    // Get mode and populate top people, cities and regions
    var mode, entity_type, challenge_id, i, tabs;

    entity_type = event.params.entity;
    mode = event.params.mode;
    challenge_id = event.params.challenge;

    get(
      `/summary-top-entities?entity_type=${entity_type}&challenge_id=${challenge_id}&mode=${mode}&target=${this.summaryCardDetailsTarget.id}`,
      {
        responseKind: "turbo-stream",
      }
    );

    // Change active tab state
    tabs = this.element.getElementsByClassName("daisy-tab");

    for (i = 0; i < tabs.length; i++) {
      tabs[i].className = tabs[i].className.replace("daisy-tab-active", "");
    }

    event.currentTarget.className += " daisy-tab-active";
  }

  toggle_f2wcr_dropdown() {
    this.f2wcrDropdownTarget.classList.toggle("hidden");
  }

  render_f2wcr_ranking(event) {
    var city_id,
      mode,
      gender,
      challenge_id,
      global_gender_toggle,
      city_gender_toggle,
      city_id_div,
      tabs,
      all_tab,
      i;

    // Get mode, gender & city_id
    mode = event.params.mode;
    gender = event.params.gender;
    challenge_id = event.params.challenge;

    if (event.srcElement.parentElement.id == "city-gender-filter") {
      city_id_div = document.getElementById("city-id-div");
      city_id = city_id_div.innerText;
    } else {
      city_id = event.srcElement.selectedOptions[0].value;
    }

    if (city_id != "all") {
      // Update ranking card using fetch call
      get(
        `/leaderboard-details?challenge_id=${challenge_id}&mode=${mode}&gender=${gender}&city_id=${city_id}&target=${this.rankingDetailsTarget.id}`,
        {
          responseKind: "turbo-stream",
        }
      );

      // Set cityID in the city-id-div
      get(`/set-city-id?city_id=${city_id}&target=${this.cityIdDivTarget.id}`, {
        responseKind: "turbo-stream",
      });

      // Hide global gender filter
      global_gender_toggle = this.globalGenderToggleTarget;
      city_gender_toggle = this.cityGenderToggleTarget;

      if (city_id != 0) {
        if (!global_gender_toggle.classList.contains("hidden")) {
          global_gender_toggle.classList.add("hidden");
          global_gender_toggle.classList.remove("flex");

          city_gender_toggle.classList.remove("hidden");
          city_gender_toggle.classList.add("flex");
        }
      } else {
        if (global_gender_toggle.classList.contains("hidden")) {
          global_gender_toggle.classList.add("flex");
          global_gender_toggle.classList.remove("hidden");

          city_gender_toggle.classList.remove("flex");
          city_gender_toggle.classList.add("hidden");
        }
      }

      // Change active tab state for gender toggle
      tabs = this.element.getElementsByClassName("city-tab");

      for (i = 0; i < tabs.length; i++) {
        tabs[i].className = tabs[i].className.replace(
          "bg-gray-200 hover:bg-gray-200 hover:cursor-default",
          "bg-white hover:cursor-pointer hover:bg-gray-100"
        );
      }

      if (event.srcElement.parentElement.id == "city-gender-filter") {
        event.currentTarget.className +=
          " bg-gray-200 hover:bg-gray-200 hover:cursor-default";
      } else {
        all_tab = document.getElementById("all-tab");

        all_tab.classList.remove(
          "bg-white",
          "hover:cursor-pointer",
          "hover:bg-gray-100"
        );
        all_tab.classList.add(
          "bg-gray-200",
          "hover:bg-gray-200",
          "hover:cursor-default"
        );
      }
    } else {
      window.location.reload();
    }
  }

  render_global_f2wcr_ranking(event) {
    var challenge_id, gender, mode, tabs, i;

    // Get gender and mode
    gender = event.params.gender;
    mode = event.params.mode;
    challenge_id = event.params.challenge;

    // Update ranking card
    get(
      `/global-leaderboard-filter?challenge_id=${challenge_id}&mode=${mode}&gender=${gender}&target=${this.rankingDetailsTarget.id}`,
      {
        responseKind: "turbo-stream",
      }
    );

    // Change active tab state
    tabs = this.element.getElementsByClassName("gender-tab");

    for (i = 0; i < tabs.length; i++) {
      tabs[i].className = tabs[i].className.replace(
        "bg-gray-200 hover:bg-gray-200 hover:cursor-default",
        "bg-white hover:cursor-pointer hover:bg-gray-100"
      );
    }

    event.currentTarget.className +=
      " bg-gray-200 hover:bg-gray-200 hover:cursor-default";
  }

  remove_flash() {
    this.element.remove();
  }
}
