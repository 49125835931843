import { Controller } from "@hotwired/stimulus";
import { event } from "jquery";

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["modal", "frame"];

  connect() {
    this.modalTarget.addEventListener(
      "close",
      this.enableBodyScroll.bind(this)
    );
  }

  disconnect() {
    this.modalTarget.removeEventListener(
      "close",
      this.enableBodyScroll.bind(this)
    );
  }

  open() {
    this.modalTarget.showModal();
    document.body.classList.add("overflow-hidden");
  }

  close() {
    this.modalTarget.close();
    this.frameTarget.removeAttribute("src");
    this.frameTarget.innerHTML = "";
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.close();
    }
  }

  enableBodyScroll() {
    document.body.classList.remove("overflow-hidden");
  }

  clickOutside() {
    if (event.target === this.modalTarget) {
      this.close();
    }
  }
}
