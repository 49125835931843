import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="form-disable"
export default class extends Controller {
  static targets = ["button", "enabledText", "disabledText"];

  connect() {
    addEventListener(
      "turbo:submit-start",
      () => {
        this.buttonTarget.disabled = true;
        this.enabledTextTarget.classList = "hidden";
        this.disabledTextTarget.classList = "block";
      },
      { once: true }
    );
  }
}
