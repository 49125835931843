import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="parsley"
export default class extends Controller {
  connect() {
    // Initialize Parsley on the form element
    this.parsleyInstance = $(this.element).parsley();

    // Optional: Handle custom Parsley events or configure options here
    // this.parsleyInstance.on("field:validate", (fieldInstance) => {
    //   console.log(`Validating field: ${fieldInstance.$element.attr("name")}`);
    // });

    // this.parsleyInstance.on("form:success", () => {
    //   console.log("Form is valid!");
    // });

    // this.parsleyInstance.on("form:error", () => {
    //   console.log("Form validation failed!");
    // });
  }
}
